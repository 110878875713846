import React, { FC, ReactNode, useEffect } from 'react';
import { Box, IconButton, LinearProgress } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Dialog } from '@uvgo-shared/dialog';
import { AlertStore } from '@uvgo-shared/alert';
import { PrimaryButton, SecondaryButton } from '@uvgo-shared/buttons';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import {
  AirportHoursModel,
  AirportHoursStore,
  AirportHoursTypeModel,
  AirportSettingsStore,
  ConditionModel,
  ConditionValueModel,
} from '../../../../Shared';
import { useStyles } from './OtOrHoursDetails.styles';
import AirportHoursGrid, { PureHoursGrid } from '../AirportHoursGrid/AirportHoursGrid';
import { takeUntil, finalize } from 'rxjs/operators';
import { AspectRatio } from '@material-ui/icons';
import { IClasses, SettingsTypeModel, UIStore, Utilities } from '@wings-shared/core';
import { useUnsubscribe } from '@wings-shared/hooks';
import { observable } from 'mobx';

interface Props {
  classes?: IClasses;
  airportHoursModel: AirportHoursModel;
  airportHoursStore: AirportHoursStore;
  airportSettingsStore: AirportSettingsStore;
  updateGridItem?: (updatedModel: AirportHoursModel[]) => void;
}

const OtOrHoursDetails: FC<Props> = ({
  airportHoursModel,
  airportHoursStore,
  airportSettingsStore,
  updateGridItem,
}) => {
  const classes = useStyles();
  const unsubscribe = useUnsubscribe();
  const gridRef = observable({
    data: React.createRef<PureHoursGrid>(),
  });
  const hasError: boolean = Boolean(gridRef.data.current?.hasErrorInGrid || gridRef.data.current?.isRowEditing);

  /* istanbul ignore next */
  // Load Data on Mount
  useEffect(() => {
    createOTORRecords();
  }, []);

  /* istanbul ignore next */
  const getAirportHoursTemplate = (
    airportHours: AirportHoursModel,
    conditionValue: SettingsTypeModel
  ): AirportHoursModel => {
    const airportHoursSubType = airportSettingsStore.airportHourSubTypes.find(({ name }) =>
      Utilities.isEqual(name, 'operational hours')
    );
    const { conditionTypes, conditionalOperators } = airportSettingsStore;
    const condition = new ConditionModel({
      ...airportHours.condition,
      //conditionValues: 'On Request',
      conditionValues: [
        new ConditionValueModel({ entityValue: conditionValue.name, entityValueId: conditionValue.id }),
      ],
      conditionType: conditionTypes.find(({ name }) => Utilities.isEqual(name, 'overtime')),
      conditionalOperator: conditionalOperators.find(
        ({ operator }) => Utilities.isEqual(operator, 'Equal') || Utilities.isEqual(operator, '=')
      ),
    });
    return new AirportHoursModel({
      ...airportHours,
      id: 0,
      condition,
      airportHoursSubType,
      cappsComment: 'OT/OR',
    });
  };

  // Needs to call using REF from Parent
  /* istanbul ignore next */
  const createOTORRecords = (): void => {
    airportSettingsStore.loadOvertime().subscribe(response => {
      const conditionType = response.find(x => Utilities.isEqual(x.label, 'On Request'));

      if (!conditionType) {
        AlertStore.important('On Request not found');
        return;
      }
      const defaultModel = getAirportHoursTemplate(airportHoursModel, conditionType);
      // create unique cappsSequenceId for new OT/OR Hours
      const cappsSequenceIds: number[] = [
        defaultModel.cappsSequenceId,
        ...airportHoursStore.airportHours.map(({ cappsSequenceId }) => cappsSequenceId),
      ];
      const cappsSequenceId: number = Math.max(...cappsSequenceIds);
      airportHoursStore.createOTORHours(defaultModel, cappsSequenceId);
    });
  };

  const upsertAirportHours = (): void => {
    const gridRows = gridRef.data?.current?.gridRows || [];
    UIStore.setPageLoader(true);
    airportHoursStore
      .upsertAirportHours(gridRows.map(x => x.serialize()))
      .pipe(
        finalize(() => UIStore.setPageLoader(false)),
        takeUntil(unsubscribe.destroy$)
      )
      .subscribe({
        next: (response: AirportHoursModel[]) => {
          ModalStore.close();
          updateGridItem && updateGridItem(response);
        },
        error: error => AlertStore.critical(error.message),
      });
  };

  const dialogActions = (): ReactNode => {
    return (
      <Box width="100%">
        {UIStore.pageLoading ? <LinearProgress /> : <></>}
        <Box display="flex" justifyContent="end" mt="5px">
          <PrimaryButton variant="contained" onClick={() => ModalStore.close()} disabled={UIStore.pageLoading}>
            Cancel
          </PrimaryButton>
          <SecondaryButton
            variant="contained"
            onClick={() => upsertAirportHours()}
            disabled={hasError || UIStore.pageLoading}
          >
            Save
          </SecondaryButton>
        </Box>
      </Box>
    );
  };

  const title = (): ReactNode => {
    return (
      <div>
        {`Airport Hours - ${airportHoursModel.airport?.operationalCode}`}
        <IconButton onClick={() => gridRef.data?.current?.autoSizeColumns()}>
          <AspectRatio />
        </IconButton>
      </div>
    );
  };

  return (
    <Dialog
      open={true}
      title={title()}
      isLoading={() => UIStore.pageLoading}
      classes={{
        paperSize: classes.modalWidth,
        header: classes.headerWrapper,
        dialogWrapper: classes.headerWrapper,
      }}
      onClose={() => ModalStore.close()}
      dialogContent={() => (
        <AirportHoursGrid
          ref={gridRef.data}
          isOtOrRecord={true}
          isEditable={true}
          rowData={airportHoursStore.otorAirportHours}
          airportHoursStore={airportHoursStore}
          airportSettingsStore={airportSettingsStore}
          airportHoursType={airportHoursModel.airportHoursType as AirportHoursTypeModel}
        />
      )}
      dialogActions={dialogActions}
    />
  );
};

export default observer(OtOrHoursDetails);
