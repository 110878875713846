export enum REVIEW_COMPARISON_FILTERS {
    AIRPORT = 'Airport Name',
    AIRPORT_HOURS_TYPE = 'Airport Hours Type',
    APPROVAL_STATUS = 'Status',
  }

export enum APPROVAL_MERGE_STATUS {
    NOT_MERGED = 1,
    MERGED,
    FAILED,
    REJECTED
  }

export enum AIRPORT_HOURS_STATUS {
    ADDED = 1,
    MODIFIED
  }
