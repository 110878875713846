import { CoreModel, IdNameCodeModel, modelProtection, SettingsTypeModel } from '@wings-shared/core';
import { IAPIAirportHourReview } from '../Interfaces';
import { UplinkAirportHourReviewModel } from './UplinkAirportHourReview.model';
import { AIRPORT_HOURS_STATUS, APPROVAL_MERGE_STATUS } from '../Enums';

@modelProtection
export class AirportHourReviewModel extends CoreModel {
  cappsSequenceId: number;
  airportHoursType: SettingsTypeModel;
  airport: IdNameCodeModel;
  mergeStatus: APPROVAL_MERGE_STATUS;
  comparisionType: AIRPORT_HOURS_STATUS;
  uplinkStagingProperties: UplinkAirportHourReviewModel[];
  // used for audit history tree reference
  path: number[];
  parentTableId: number;

  constructor(data?: Partial<AirportHourReviewModel>) {
    super(data);
    Object.assign(this, data);
    this.airport = new IdNameCodeModel(data?.airport);
    this.uplinkStagingProperties =
      data?.uplinkStagingProperties?.map(
        (model: UplinkAirportHourReviewModel) =>
          new UplinkAirportHourReviewModel({ ...model, comparisionType: data?.comparisionType })
      ) || [];
  }

  static deserialize(apiData: IAPIAirportHourReview): AirportHourReviewModel {
    if (!apiData) {
      return new AirportHourReviewModel();
    }
    const data: Partial<AirportHourReviewModel> = {
      ...apiData,
      id: apiData.id || apiData.airportHourStagingId,
      airport: IdNameCodeModel.deserialize({
        ...apiData.airport,
        code: apiData.airport?.code,
        id: apiData.airport?.airportId,
      }),
      airportHoursType: new SettingsTypeModel({
        ...apiData.airportHoursType,
        id: apiData.airportHoursType?.airportHoursTypeId,
      }),
      ...CoreModel.deserializeAuditFields(apiData),
    };
    return new AirportHourReviewModel(data);
  }

  static deserializeList(apiDataList: any[]): AirportHourReviewModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => AirportHourReviewModel.deserialize(apiData)) : [];
  }
}
