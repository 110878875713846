import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  containerClass: {
    paddingTop: theme.spacing(3.5),
  },
  buttonStyle: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));
