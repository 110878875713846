import { CoreModel, IdNameCodeModel, modelProtection, SettingsTypeModel } from '@wings-shared/core';
import { IAPIUplinkAirportHourReview } from '../Interfaces';
import { AIRPORT_HOURS_STATUS, APPROVAL_MERGE_STATUS } from '../Enums';

@modelProtection
export class UplinkAirportHourReviewModel extends CoreModel {
  tableName: string;
  propertyName: string;
  oldValue: string;
  newValueId: number | null;
  newValueCode: string;
  newValue: string;
  isList: boolean = false;
  mergeStatus: APPROVAL_MERGE_STATUS;
  comparisionType?: AIRPORT_HOURS_STATUS;

  constructor(data?: Partial<UplinkAirportHourReviewModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: IAPIUplinkAirportHourReview): UplinkAirportHourReviewModel {
    if (!apiData) {
      return new UplinkAirportHourReviewModel();
    }
    const data: Partial<UplinkAirportHourReviewModel> = {
      ...apiData,
      ...CoreModel.deserializeAuditFields(apiData),
    };
    return new UplinkAirportHourReviewModel(data);
  }

  static deserializeList(apiDataList: any[]): UplinkAirportHourReviewModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => UplinkAirportHourReviewModel.deserialize(apiData)) : [];
  }
}
