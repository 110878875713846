import { IAPIFilterDictionary, ISelectOption, Utilities } from '@wings-shared/core';
import {
  AIRPORT_HOURS_STATUS,
  AirportHourReviewModel,
  APPROVAL_MERGE_STATUS,
  REVIEW_COMPARISON_FILTERS,
} from '../Shared';
import { baseGridFiltersDictionary } from '@wings/shared';

export const gridFilters: IAPIFilterDictionary<REVIEW_COMPARISON_FILTERS>[] = [
  {
    columnId: 'airportHoursType',
    apiPropertyName: 'airportHoursType',
  },
  {
    columnId: 'entityName',
    apiPropertyName: 'EntityName',
  },
  {
    columnId: 'airportCode',
    apiPropertyName: 'AirportCode',
  },
  {
    columnId: 'status',
    apiPropertyName: 'StatusId',
    uiFilterType: REVIEW_COMPARISON_FILTERS.APPROVAL_STATUS,
  },
];

// Check if Status Is Merged or Not
export const isDataMerged = (mergeStatus: string | APPROVAL_MERGE_STATUS) => {
  return Utilities.isEqual(mergeStatus, APPROVAL_MERGE_STATUS.MERGED);
};

export const isDataRejected = (mergeStatus: string | APPROVAL_MERGE_STATUS) => {
  return Utilities.isEqual(mergeStatus, APPROVAL_MERGE_STATUS.REJECTED);
};

export const comparisonType = {
  [AIRPORT_HOURS_STATUS.ADDED]: 'Added',
  [AIRPORT_HOURS_STATUS.MODIFIED]: 'Modified',
};

export const getGridData  = (
  data: Array<any>,
  startKeys: number[],
  isChild: boolean,
  parentTableId?: number
): AirportHourReviewModel[] => {
  let _startIndex = 1;
  const tableData = data.reduce((total, item, index) => {
    if (isChild) {
      item.path = startKeys.concat(index + 1);
      item.parentTableId = parentTableId;
    } else {
      item.path = [ _startIndex ];
      _startIndex = _startIndex + 1;
    }
    total.push(AirportHourReviewModel.deserialize({ ...item, uplinkStagingProperties: [] }));
    // Check if child available
    if (item.uplinkStagingProperties?.length) {
      const result = getGridData(
        item.uplinkStagingProperties,
        item.path,
        true,
        item.uplinkStagingPropertyId
      );
      total = total.concat(result);
    }
    return total;
  }, []);
  return tableData;
};

export const mergeStatus = {
  [APPROVAL_MERGE_STATUS.NOT_MERGED]: 'Not Merged',
  [APPROVAL_MERGE_STATUS.MERGED]: 'Merged',
  [APPROVAL_MERGE_STATUS.FAILED]: 'Failed',
  [APPROVAL_MERGE_STATUS.REJECTED]: 'Rejected',
};

export const mergeStatusOptions: ISelectOption[] = [
  { label: mergeStatus[1], value: 1 },
  { label: mergeStatus[2], value: 2 },
  { label: mergeStatus[3], value: 3 },
  { label: mergeStatus[4], value: 4 },
];

